.side-info {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.side-info__item {
    position: relative;
}

.side-info__item::after {
    content: "";
    display: block;
    height: 90%;
    background-color: var(--blue);
    width: 1px;
    right: -1rem;
    position: absolute;
}

@media (min-width : 500px) {
    .side-info {
        flex-direction: column;
        gap: 2.375rem;
        justify-content: center;
    }

    .side-info__item::after {
        height: 1px;
        background-color: var(--blue);
        width: 90%;
        right: 10%;
        bottom: -1.200rem;
        position: absolute;
    }
}
