.city-name {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--dark-green);
}

.city-co {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.6em;
  line-height: 0.6rem;
  color: var(--dark-green);
}

@media (min-width: 480px) {
  .city-name {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .city-co {
    font-size: 0.75rem;
    line-height: 0.8rem;
  }
}
