main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.reset__main {
    height: 100%;
}

.reset__container {
    padding: 1rem;
    height: 100%;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.reset__p {
    margin-top: 0.5rem;
    color: var(--dark-green);
    font-family: Public Sans, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.reset__form {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reset__form .input {
    padding: 0.25rem 1rem;
    background-color: var(--light-blue);
    margin-bottom: 3rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    font-family: inherit;
    line-height: 2;
    color: var(--dark-green);
}

.reset__form input::placeholder {
    color: #718796;
    display: flex;
    letter-spacing: 0em;
}

@media screen and (min-width: 950px) {
    .background__container {
        display: block;
        height: 100%;
        width: 30%;
        overflow: hidden;
        background: url('../../public/img/bg1.png');
        background-size: cover;
    }

    main {
        flex-direction: row;
        padding: 0;
    }

    .reset__container {
        width: 70%;
        padding-left: 6rem;
        gap: 2rem;
    }

    .reset__p {
        width: 50%;
        margin-top: 2rem;
        font-size: 1.125rem;
    }

    .reset__form {
        width: 25%;
    }

    .reset__form .input {
        width: 100%;
    }

    .reset__title {
        width: 70%;
    }
}
