input {
  all: unset;
}

.city-searchbar {
  /* position: absolute; */
  gap: 0.625rem;
  width: 80vw;
  display: flex;
  justify-content: space-between;
}

.city-searchbar__main {
  position: relative;
  flex-grow: 1;
  background-color: var(--light-blue);
  border-radius: 32px;
}

.city-searchbar__main--input {
  position: relative;
  z-index: 1;
  height: 2.25rem;
  display: flex;
  align-items: center;
}

.city-searchbar__main--input__text {
  height: 100%;
  width: 85%;
  padding-left: 5%;
  flex-grow: 1;
}

.city-searchbar__main--input__separator {
  height: 66%;
  width: 1px;
  background-color: var(--blue);
}

.city-searchbar__main--input__geoloc {
  padding: .625rem;
}

.city-searchbar__main--input__geoloc--img {
  cursor: pointer;
}

.city-searchbar__button {
  background-color: var(--red);
}

::placeholder {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: .875rem;
  color: var(--dark-green);
}

.search-results {
  top: 18px;
  padding: 15px 0 1rem 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.2rem;
  color: var(--dark-green);
  background-color: var(--light-blue);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  cursor: pointer;
}

/* .search-results__separators {
    height : 1px;
    width : 90%;
    margin-left: 5%;
    background-color: var(--blue);
} */

.search-results__li::before {
  content: "";
  display: block;
  width: 90%;
  background-color: var(--blue);
  height: 1px;
  bottom: 0;
  position: absolute;
}

.city-searchbar__main--input__text[type="text"] {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: .875rem;
  color: var(--dark-green);
}

.search-results__li {
  background-color: var(--light-blue);
  text-decoration: none;
  color: var(--dark-green);
  padding: 6px 5%;
  position: relative;
}

.search-results__li:hover {
  background-color: var(--blue);
}

@media (min-width: 950px) {

  .city-searchbar {
    gap: 1rem;
    width: 100%;
    max-width: 37rem;
  }

  .city-searchbar__main--input {
    height: 2.75rem;
  }

  .city-searchbar__main--input__geoloc {
    padding: 1rem;
  }

  ::placeholder {
    font-size: 1rem;
    line-height: 1.25px;
  }

  .city-searchbar__main--input__text[type="text"] {
    font-size: 1rem;
    line-height: 1.25px;
  }

  .search-results {
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--dark-green);
    top: 20px;
    padding: 30px 0 1rem 0;
  }
}
