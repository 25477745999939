.search__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.search__container .title__text {
  margin-bottom: 2rem;
}
