.main-info {
  position: relative;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  max-width: fit-content;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  color: #FFFFFF;
  width: 100%;
}

.main-info__center {
  position: relative;
  aspect-ratio: 1 / 1;
  border-radius: 1rem;
  overflow: hidden;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}

.main-info__center.hide {
  opacity: 0;
}

.main-info__center-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  height: 10%;
  width: 100%;
  background: rgba(2, 43, 58, 0.25);
  backdrop-filter: blur(1px);
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid #FFFFFF;
}

.main-info__center-footer span {
  font-weight: 700;
  font-size: 0.9rem;
}

.main-info__side {
  margin: 0.5rem;
  overflow-x: scroll;
}

.main-info__side.hide {
  opacity: 0;
}

.main-info__center-data {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  left: 1rem;
  gap: .5rem;
}

.main-info__center-data-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20%;
}

.main-info__center-data-day {
  font-weight: 700;
  font-size: 1.1rem;
}

.main-info__center-data-main span {
  font-weight: 400;
  font-size: 3rem;
}

.main-info__center-data-main-icon {
  height: 4rem;
}

.loader {
  display: none;
}

.loader.hide {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 120px;
  height: 120px;
  /* animation: blur 2s ease-out infinite; */
}

.loader .line1 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
}

.loader .line2 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .3s;
}

.loader .line3 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .2s;

}

.loader .line4 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .1s;
}


@keyframes blur {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}

@media (min-width: 500px) {
  .main-info {
    flex-direction: row-reverse;
  }

  .main-info__side {
    overflow-x: visible;
    padding: 1rem;
    margin: 0;
    min-width: 5.875rem;
  }

  .main-info__center {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  }

  .main-info__center-footer span {
    font-size: 1.1rem;
  }

  .main-info__center-data {
    top: 1.5rem;
    left: 2rem;
    gap: .5rem;
  }

  .main-info__center-data-day {
    font-size: 1.5rem;
  }

  .main-info__center-data-main span {
    font-size: 4rem;
  }

  .main-info__center-data-main-icon {
    height: 6rem;
  }
}
