main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.error__main {
    height: 100%;
}

.error__background--container {
    display: none;
}

.error__container {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    /* margin-bottom: 50%; */
    height: 75%;
}

.error__p {
    color: var(--dark-green);
    font-family: Public Sans, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media screen and (min-width: 950px) {
    .error__background--container {
        display: block;
        height: 100%;
        width: 30%;
        overflow: hidden;
        background: url('../../public/img/bg1.png');
        background-size: cover;
    }

    main {
        flex-direction: row;
        padding: 0;
    }

    .error__container {
        width: 70%;
        padding: 6rem;
        gap: 2rem;
        height: 80%;
    }

    .error__p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}
