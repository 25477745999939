.title__text {
  display: inline-block;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.5rem;
  color: var(--dark-green);
  /* margin-bottom: 2rem; */
}

.title__point {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.625rem;
  background-color: var(--green);
}

@media (min-width: 480px) {
  .title__text {
    font-size: 4rem;
    line-height: 5.5rem;
  }

  .title__point {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
  }
}

@media (min-width: 1024px) {
  .title__text {
    font-size: 6rem;
    line-height: 7rem;
  }

  .title__point {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
  }
}
