.board__main {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  background: url("../images/board4.jpg") no-repeat bottom;
  background-size: contain;
}

.board__main--credits {
  font-size: 0.75rem;
  font-family: "Public Sans", sans-serif;
  color: var(--white);
}

.board__main--credits a {
  color: var(--white);
}

.all__boards {
  width: 100%;
  overflow: hidden;
}

.all__boards-wrapper {
  display: flex;
}

.board__container {
  width: 100%;
  display: flex;
  max-width: 112rem;
  flex-grow: 1;
  align-items: center;
}

.board__arrow {
  cursor: pointer;
  display: none;
}

.board__arrow.right {
  transform: rotate(180deg);
}

.nav__dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 1rem .5rem;
}

.nav__dot.active {
  border: 2px solid var(--white);
}

.nav__dot.inactive {
  background: var(--white);
}

.nav__dot-container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 948px) {
  .board__main {
    padding: 4rem;
  }

  .board__arrow {
    display: block;
    padding-right: 2rem;
  }
}
