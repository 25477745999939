.widget-air-infos__air-qual {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    width: 100%;
}

.widget-air-infos__air-qual--graph {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
}

.widget-air-infos__air-qual--graph__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.widget-air-infos__air-qual--graph__column--bar {
    width: 12px;
    border-radius: 32px;
}

.widget-air-infos__air-qual--graph__column--unit {
    text-align: center;
    font-family: Public Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.widget-air-infos__air-qual--separator {
    width: 1px;
    height: 128px;
    background: #E1E5F2;
    border-radius: 16px;
}

.widget-air-infos__air-qual--grade {
    margin-left : 1rem;
    margin-right : 0.5rem;
}

.widget-air-infos__air-qual--data {
    font-size: 3rem;
}

.widget-air-infos__air-qual--unit {
    font-size: 2rem;
}

.widget-air-infos__air-qual--footer {
    font-size: 1rem;
    font-weight: 700;
}

.widget-air-infos__separator {
    width: 90%;
    height: 1px;
    border-radius: 16px;
    background: #E1E5F2;
}

.widget-air-infos__wind {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    margin-bottom: 1rem;
    width: 100%;
}

.widget-air-infos__wind--compass {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
}

.widget-air-infos__wind--barometer {
    position: relative;
}
