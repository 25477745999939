.background__container--data {
    display: none;
  }

.data-policy__container {
    padding: 1rem;
    height: 100%;
}

.data-policy__content {
    font: Public-Sans, sans-serif;
    color: var(--dark-green);
}

.data-policy__content h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
}

.data-policy__content p {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: justify;
    line-height: normal;
}

@media screen and (min-width: 950px) {

    main {
        flex-direction: row;
        padding: 0;
    }

    .data-policy__container {
        width: 70%;
        padding: 3rem 6rem;
    }

    .data-policy__content {
        margin-top: 2rem;
    }

    .data-policy__content h3 {
        margin-top: 1.5rem;
        margin-bottom: 0.8rem;
        font-size: 2rem;
    }
    
    .data-policy__content p {
        margin-top: 0.8rem;
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 1440px) {

    .background__container--data {
        display: block;
        height: 100%;
        width: 30%;
        overflow: hidden;
        background: url('../../public/img/bg1.png');
        background-size: cover;
    }

    .data-policy__title {
        width: 80%;
    }

    .data-policy__content {
        width: 80%;
        margin-top: 3rem;
    }
}
