.widget-weekforecast__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.widget-weekforecast__separator {
    margin-top : 0.2rem;
    width: 2rem;
    height: 1px;
    background-color: var(--dark-green);
}

.widget-weekforecast__body--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap : 0.5rem;
}

.widget-weekforecast__body--row__day {
    flex-grow: 1;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    text-transform: none;
}

.widget-weekforecast__body--row__icon {
    width: 20px;
}

.widget-weekforecast__body--row__temp {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (min-width: 800px) {
    .widget-weekforecast__body--row__day {
        font-size: 1rem;
    }
    
    .widget-weekforecast__body--row__icon {
        width: 26px;
    }
    
    .widget-weekforecast__body--row__temp {
        font-size: 0.875rem;
    }
}
