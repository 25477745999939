.modal__background {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.50);
  padding: 1rem;
}

.modal__background.hide {
  height: 0;
  overflow: hidden;
  padding: 0;
}

.modal__container {
  background-color: var(--white);
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  height: 70vh;
  width: 100%;
  padding: 1.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.modal__grid {
  background-color: #F1F1F1;
  padding: .5rem;
  border-radius: .75rem;
  height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(9.5rem, 10.5rem);
  grid-gap: .5rem;
}

.modal__grid-item.selected {
  border: 2px solid var(--green);
  border-radius: 1rem;
}

.modal__grid-item.unselected {
  opacity: .3;
  cursor: not-allowed;
}


.subtitle {
  font-family: "Public Sans", sans-serif;
  font-size: 1.5rem;
  color: var(--dark-green);
  font-weight: 700;
  margin: 0 0 1rem 0;
}

.btn__container {
  display: flex;
  justify-content: space-between;
  max-width: 12rem;
  padding: 1rem 0 0 0;
  align-self: center;
  width: 100%;
}

@media screen and (min-width: 528px) {
  .modal__background {
    padding: 2rem;
  }

  .modal__container {
    width: 37rem;
    padding: 2.5rem;
    justify-content: center;
    align-items: center;
    transform: none;
    top: 0;
    position: relative;
  }

  .subtitle {
    font-size: 2rem;
  }

  .subtitle .title__point {
    width: .75rem;
    height: .75rem;
    margin-left: .25rem;
  }

  .btn__container {
    max-width: 17rem;
  }
}


@media screen and (min-width: 948px) {
  .modal__background {
    padding: 4rem;
  }

  .modal__container {
    width: 37rem;
    padding: 2.5rem;
    justify-content: center;
    align-items: center;
    transform: none;
    top: 0;
    position: relative;
  }

  .subtitle {
    font-size: 2.5rem;
  }

  .subtitle .title__point {
    width: .75rem;
    height: .75rem;
    margin-left: .25rem;
  }

  .btn__container {
    max-width: 20rem;
  }
}
