.widget-sungraph__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: normal;
    line-height: normal;
    text-transform: none;
    width: 100%;
    padding: 8px;
}

.widget-sungraph__header--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}

.widget-sungraph__header--container__title {
    font-size: 0.75rem;
    font-weight: 400;
}

.widget-sungraph__header--container__data {
    font-size: 1.5rem;
    font-weight: 700;
}

.widget-sungraph__data {
    position: relative;
    padding: 8px;
    width: 324px;
    flex-grow: 1;
}

.widget-sungraph__data--path__top {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
}

.widget-sungraph__data--path__bot {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
}

.widget-sungraph__data--line {
    z-index: 0;
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    background-color: var(--light-blue);
}

.widget-sungraph__data--cursor {
    position: absolute;
    offset-path: path("M-2 65 C 110 58 105.595 1.5 160 5.5 C 204 1.5 200 60 318 65"); 
    border: 2px solid var(--white);
    transform : translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    z-index: 2;
}
