.widget-wind-data {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.widget-wind-unit {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
