.small-weather {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--dark-green);
    gap : 0.5rem
}

.small-weather__day {
    font-size: 14px;
    font-weight: bold;
}

.small-weather__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;

}

.small-weather__data p {
    font-size: 1rem;
}

.small-weather__data-icon {
    height: 1.5rem;
}

@media (min-width : 500px) {
    .small-weather {
        gap : 1rem
    }

    .small-weather__day {
        font-size: 1rem;
    }
    
    .small-weather__data p {
        font-size: 1.5rem;
    }

    .small-weather__data-icon {
        height: 2rem;
    }
}
