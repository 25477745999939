.compass {
    position: absolute;
    width: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.compass-arrow {
    position: absolute;
    width: 30px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
}
