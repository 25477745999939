form {
  margin-top: 2rem;
}

.password__checker {
  margin: -1rem 0 1.5rem 0;
  color: var(--dark-green);
  font-family: Public Sans, sans-serif;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.password__indication {
  padding-left: .5rem;
  margin-bottom: .5rem;
  transition: all .3s ease-in;
}

.password__indication.valid {
  color: var(--light-blue);
}

.progress__bar {
  width: 100%;
  height: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
  margin: .5rem 0 .5rem 0;
  padding: 0 .5rem 0 .5rem;
}

.progress__bar-item {
  width: 100%;
  height: 100%;
  background: var(--light-blue);
  border-radius: 2px;
  transition: all .3s ease-in;
}

.progress__bar-item.show {
  background: var(--dark-green);
}
