.barometer__arrow {
    position: absolute;
    /* bottom: 50%; */
    left: 50%;
    top: -2px;
    transform-origin: bottom;
}

.barometer__infos {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    line-height: normal;
    text-transform: none;
    font-style: normal;
    text-align: center;

}

.barometer__infos--data {
    font-size: 1.125rem;
    font-weight: 700;
}

.barometer__infos--unit {
    font-size: 0.75rem;
    font-weight: 400;
}

.barometer__icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8%;
    width: 1.5rem;
}
