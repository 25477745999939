.card {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 17 / 16;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.card__img {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0 0;
}

.card__text-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  color: var(--dark-green);
}

.card__text-container--title {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.card__text-container--subtitle {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.card__link {
  position: absolute;
  bottom: .6rem;
  right: 1rem;
  margin-left: auto;
}

.card__link--image {
  width: 1rem;
}

@media (min-width: 480px) {
  .card {
    width: 100%;
  }

  .card__text-container {
    gap: 1.5rem;
  }

  .card__text-container--title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .card__text-container--subtitle {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .card__link {
    bottom: 1rem;
  }

  .card__link--image {
    width: 1.4rem;
  }
}

/*@media (min-width: 1440px) {*/
/*  .card__text-container {*/
/*    padding: 2rem;*/
/*    gap: 2rem;*/
/*  }*/

/*  .card__text-container--title {*/
/*    font-size: 2.5rem;*/
/*  }*/

/*  .card__text-container--subtitle {*/
/*    font-size: 1.25rem;*/
/*    line-height: 2rem;*/

/*  }*/
/*}*/
