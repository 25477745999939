.widget-daytemp__header {
    position: relative;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
}

.widget-daytemp__center {
    position: relative;
    height: 2px;
    flex-grow: 1;
    background-color: var(--dark-green);
}

.widget-daytemp__center--cursor {
    position: absolute;
    width: 4px;
    height: 4px;
    border: solid 1px var(--dark-green);
    outline: 1px solid white;
    border-radius: 2px;
    background-color: white;
    top: 50%;
    transform: translate(-50%, -50%);
}

.widget-daytemp__body {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.widget-daytemp__body--icon {
    width: 2.5rem;
}

.widget-daytemp__body--data {
    flex-grow: 1;
    font-size: 2.5rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

@media (min-width: 480px) {
    .widget-daytemp__body--icon {
        width: 3rem;
    }

    .widget-daytemp__body--data {
        font-size: 3rem;
    }
}

.widget-daytemp__footer {
    display: flex;
    gap: 0.15rem;
    align-self: flex-end;
}

.widget-daytemp__footer--icon  {
    width: 0.5rem;
}

.widget-daytemp__footer--data {
    font-size: 0.75rem;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    text-transform: none;
}
