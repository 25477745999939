.results-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  min-height: 80vh;
}

.results-container__city-header {
  align-self: flex-start;
}

.results-container__city-results {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.results-container__city-results--data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.results-container__widgets-grid {
  width: 100%;
}

.results-container__searchbar {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  width: 100%;
}

.results-container__credits {
  align-self: center;
  font-size: 0.75rem;
  font: "Public Sans", sans-serif;
  color: var(--dark-green);
  margin-top: 1rem;
}

.results-container__credits a {
  color: var(--dark-green);
}

@media (min-width: 480px) {

  .results-container__widgets-grid {
    max-width: 645px;
  }
}

@media (min-width: 1200px) {
  .results-container {
    gap: 2rem;
    align-items: center;
    margin-bottom: 6rem;
  }

  .results-container__city-header {
    align-self: flex-start;
    margin-left: 3rem;
  }

  .results-container__city-results--data {
    flex-direction: row;
  }

  .results-container__widgets-grid {
    width: 518px;
  }
}
