main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main__login {
  height: 100%;
}

.background__container {
  display: none;
}

.login__utils-container {
  text-align: center;
  margin-top: 6rem;
  color: var(--dark-green);
}

.login__utils-container p {
  padding: 1rem 0rem;
}

.login__utils-container .link {
  color: var(--green);
}

.form.loading {
  opacity: .5;
}

.form__wrapper {
  position: relative;
}

@media screen and (min-width: 950px) {
  .background__container {
    display: block;
    height: 100%;
    width: 30%;
    overflow: hidden;
    background: url('../../public/img/bg1.png');
    background-size: cover;
  }

  main {
    flex-direction: row;
    padding: 0;
  }

  .login__container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    min-width: 35%;
  }
}
