.update__container {
    padding: 1rem;
    height: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.update__new-password {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--dark-green);
    font-family: Public Sans, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 2rem;

}

.update-password-btn {
    margin-top: 0rem;
}

@media screen and (min-width: 950px) {
    .background__container {
        display: block;
        height: 100%;
        width: 30%;
        overflow: hidden;
        background: url('../../public/img/bg1.png');
        background-size: cover;
    }

    main {
        flex-direction: row;
        padding: 0;
    }

    .update__container {
        width: 70%;
        padding-left: 6rem;
        gap: 0.5rem;
    }

    .update-password-form {
        margin-left: 2.5rem;
    }

    .update__new-password {
        font-size: 1.125rem;
        margin-top: 4rem;
    }

    .update-password-btn {
        margin-top: 3rem;
    }
}
