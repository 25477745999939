:root {
  --dark-green: #022b3a;
  --green: #1f7a8c;
  --blue: #bfdbf7;
  --light-blue: #e1e5f2;
  --white: #ffffff;
  --red: #d61f1f;
}

html {
  height: 100%;
  /*min-height: 100vh;*/
  width: 100%;
  scroll-behavior: smooth;
}

/* Works on Firefox */
html {
  scrollbar-color: var(--light-blue);
}

/* Works on Chrome, Edge, and Safari */
html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--light-blue);
}


::selection {
  background: var(--light-blue);
}

input::selection {
  background: var(--blue);
}

body {
  height: 100%;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  position: relative;
}

#root {
  /*height: 100%;*/
  /*width: 100%;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

main {
  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.link {
  text-decoration: none;
  position: relative;
  width: fit-content;
}

.link::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform-origin: left;
  display: block;
}

.form {
  display: flex;
  flex-direction: column;
}

.form__field {
  display: flex;
  flex-direction: column;
}

.form label {
  color: var(--dark-green);
  padding-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.form .input {
  padding: 0.25rem 1rem;
  background-color: var(--light-blue);
  margin-bottom: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-family: inherit;
  line-height: 2;
  color: var(--dark-green);
}

.form input::placeholder {
  color: #718796;
  display: flex;
  letter-spacing: 0em;
}

.password__input {
  padding: 0.25rem 1rem;
  padding-right: 3rem;
  position: relative;
  background-color: var(--light-blue);
  margin-bottom: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-family: inherit;
  line-height: 2;
  color: var(--dark-green);
  width: 100%;
  box-sizing: border-box;
}

.password__input-container {
  width: 100%;
  position: relative;
}

input[type="password"] {
  letter-spacing: 0.125em;
}

.password__toggle {
  right: 1rem;
  top: calc(50% - 0.75rem);
  transform: translateY(-50%);
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 3;
  cursor: pointer;
}

.form__submit {
  max-width: fit-content;
  align-self: center;
  margin-top: 3rem;
}

.form__submit.invalid {
  background-color: var(--blue);
  cursor: default;
  color: var(--white);
  border: 1px solid var(--white);
}

.form__warning {
  font-family: "Public Sans", sans-serif;
  font-size: .75rem;
  font-weight: normal;
  color: #d61f1f;
  opacity: 0;
  transition: all .2s ease-in;
  margin-top: -1rem;
  padding-left: .5rem;
  margin-bottom: .5rem;
}

.form__warning.show {
  opacity: 1;
}

.section__content {
  max-width: 80rem;
}
