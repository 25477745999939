.widget-sun-hours {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.widget-sun-hours__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap : 0.75rem;
}

.widget-sun-hours__container--data {
    text-transform: none;
    margin-top: 0.5rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
