.loader__component {
  display: block;
  width: 60px;
  height: 60px;
  margin: 2rem 0;
}

.loading__main {
  min-height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__container-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.loader__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__component .line1 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
}

.loader__component .line2 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .3s;
}

.loader__component .line3 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .2s;

}

.loader__component .line4 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .1s;
}
