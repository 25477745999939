.home_main {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home_header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  width: 100%;
  min-height: 65vh;
}

.header_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_window {
  display: none;
}

.home_phone {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.about {
  padding: 6rem 1rem;
  background-color: rgba(240, 242, 249, 0.70);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
}

.about__section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.about__section.section__content {
  max-width: 60rem;
}

.about__title {
  margin-bottom: 2.25rem;
}

.about__text {
  color: var(--dark-green);
  font-family: Public Sans, sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.about__link {
  margin-top: 2rem;
}

.func {
  padding-top: 6rem;
  margin-bottom: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

.func__title {
  margin-bottom: 2rem;
}

.func__cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
}

@media screen and (min-width: 640px) {
  .func {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {

  .header_main {
    padding: 2rem;
    width: 100%;
  }

  .header_window {
    padding: 2rem;
  }

  .home_phone {
    display: none;
  }

  .header_window {
    display: block;
  }

  .home_header {
    flex-direction: column;
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .about {
    padding: 8rem;
  }

  .about__text {
    font-size: 1.125rem;
  }

  .about__link {
    margin-top: 2.5rem;
  }

  .func {
    padding-top: 10rem;
    margin-bottom: 10rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .func__title {
    align-self: flex-start;
    margin-bottom: 4rem;
  }

  .func__cards {
    grid-gap: 4rem;
  }
}

@media screen and (min-width: 1048px) {
  .func__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .about {
    padding: 12rem 25rem;
  }

  .home_header {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1450px) {
  .about__title .title__text {
    font-size: 7rem;
  }
}
