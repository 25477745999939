.footer {
  background-color: var(--dark-green);
  color: var(--light-blue);
  padding: 1rem;
}

.footer__h3 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer__logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.footer__logo {
  height: 2rem;
}

.footer__column {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}

.creator {
  margin-bottom: 0.5rem;
}

.link {
  color: var(--light-blue);
}

.link::after {
  background: var(--light-blue);
}

.footer__nav-li {
  margin-bottom: 1rem;
}

.footer__contact-li {
  margin-bottom: 3rem;
}

@media screen and (min-width: 950px) {
  .footer {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .footer__column {
    margin-bottom: 0;
    width: 25%;
    align-items: center;
  }

  .creator {
    margin-bottom: 1rem;
  }

  .footer__nav-li {
    margin-bottom: 1.5rem;
  }
}
