.widget-air-qual-data {
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.widget-air-qual-unit {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.widget-air-qual-footer {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
}

.widget-visibility-gradient {
    position: relative;
    margin-top: 0.5rem;
    background: linear-gradient(270deg, #9AD64E 0%, #9AD64E 0.01%, #F9CE39 17.71%, #F78E49 31.88%, #F78E49 49.13%, #F65E5F 61.98%, #A070B6 82.29%, #9E6A7B 100%);
    width: 100%;
    height: 0.125rem;
}

.widget-visibility-cursor {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #9BD74E;
    border: 1px solid #FFF;
}
