.navbar {
  padding: 0.5rem 1rem;
  border-bottom: 2px solid var(--light-blue);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}

.navbar__container {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  background-color: var(--white);
  width: 100%;
  height: calc(100vh - 3rem - 2px);
  /*display: none;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(100%);
  transition: transform .2s ease-in;
}

.navbar__container.show {
  /*display: flex;*/
  transform: translateX(0%);
}

.navbar__container--ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}

.navbar__container--li {
  padding: 2rem;
}

.navbar__container--link.active {
  color: var(--green);
}

.navbar__separator {
  height: 2px;
  width: 4rem;
  background-color: var(--light-blue);
}

.navbar__container--link {
  font-size: 2rem;
  font-weight: 700;
  color: var(--dark-green);
  text-decoration: none;
}

.navbar__container--button {
  width: 60%;
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 2rem;
  height: 2rem;
}

.line {
  fill: none;
  stroke: var(--dark-green);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.navbar__container--hamburger {
  width: 2rem;
  height: 2rem;
}

.account__link {
  text-decoration: none;
  color: var(--dark-green);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.account__link:hover p {
  color: var(--green);
}

.account__link:hover path {
  fill: var(--green);
}

.account__link p {
  font-size: 1.25rem;
  padding: 0 .5rem;
}

@media screen and (min-width: 950px) {

  .navbar {
    display: flex;
    justify-content: space-between;
    padding: .5rem 2rem;
  }

  .navbar__container--ul {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0rem 2rem;
  }

  .navbar__container--link {
    font-size: 1rem;
    font-weight: 400;
  }

  .navbar__container--li {
    padding: 0rem 1rem;
    text-align: center;
  }

  .navbar__container--link:hover {
    color: var(--green);
  }

  .navbar__separator {
    height: 2rem;
    width: 2px;
  }

  .navbar__container--button {
    min-width: auto;
    width: auto;
    align-items: center;
  }

  .navbar__connectLink {
    color: var(--dark-green);
    margin-right: 1rem;
    font-size: 1rem;
    text-decoration: none;
  }

  .navbar__logo {
    height: 1.5rem;
  }

  .navbar__connectLink:hover {
    color: var(--green);
  }

  .account__link p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1250px) {
  .navbar__container--li {
    padding: 0rem 3rem;
  }
}
