.editable__grid {
  width: 100%;
  gap: 1rem;
  background-color: var(--white);
  border-radius: 1.25rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  position: relative;
  max-height: 70vh;
  overflow-y: scroll;
}

.widget-main.completion {
  box-shadow: none;
  border: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

.widget-main.completion.editable {
  border: 2px dashed rgba(2, 43, 58, 0.30);
  opacity: 1;
  cursor: pointer;
}

.widget__delete {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: .5rem;
  top: .5rem;
  cursor: pointer;
  z-index: 11;
}

.modular__grid-container {
  width: 100%;
  padding: 1rem;
  transition: transform 0.2s ease-in;
}

.modular__grid-infos {
  margin-bottom: 1rem;
}

.grid__title-container {
  display: flex;
  justify-content: space-between;
}

.grid__title {
  display: flex;
}

.modular__grid-edit {
  margin-left: 1rem;
}

.modular__grid-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.modular__grid-title {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  color: var(--dark-green);
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.modular__grid-subtitle {
  font-family: "Public Sans", sans-serif;
  font-weight: normal;
  color: var(--dark-green);
  font-size: .75rem;
}

@media screen and (min-width: 948px) {
  .editable__grid {
    max-height: none;
    overflow-y: auto;
  }

  .modular__grid-title {
    font-size: 2.5rem
  }

  .modular__grid-subtitle {
    font-size: 1rem;
  }

  .modular__grid-icon {
    width: 2rem;
    height: 2rem;
  }

  .modular__grid-container {
    padding: 2rem;
  }
}
