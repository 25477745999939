.main__account {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  position: relative;
}

.account__form {
  padding: 2rem 0;
  width: 100%;
}

.account__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.link__container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 3rem 0;
  width: 100%;
}

.link-green {
  color: var(--green);
  margin-bottom: 1rem;
}

.link-green::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -2px;
  background: var(--green);
  transform-origin: left;
  transition: width .2s ease-in;
}

.link-red {
  color: var(--red);
  margin-bottom: 1rem;
}

.link-red::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -2px;
  background: var(--red);
  transform-origin: left;
  transition: width .2s ease-in;
}

.link-red:hover::after {
  width: 0;
}

.link-green:hover::after {
  width: 0;
}

.field__container {
  width: 100%;
  margin-bottom: 2rem;
  display: block;
}

.field__container label {
  font-family: "Public Sans", sans-serif;
  font-size: 1.25rem;
  color: var(--dark-green);
  font-weight: 700;
  display: block;
  margin-bottom: .5rem;
}

.input__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--light-blue);
}

.input__container input {
  width: 100%;
  padding: .5rem .5rem .5rem 0;
  color: var(--dark-green);
  font-family: "Public Sans", sans-serif;
  font-weight: normal;
}

.input__container img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modification__container {
  display: flex;
}

.modification__container img {
  margin: 0 .25rem;
  cursor: pointer;
}

.account__container {
  width: 100%;
  max-width: 72rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete__modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.50);
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 99;
}

.delete__modal-container {
  padding: 1rem;
  background: var(--white);
  font-family: "Public Sans", sans-serif;
  color: var(--dark-green);
  border-radius: 1rem;
}

.delete__modal-container.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete__modal-container.loader {
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete__modal-close {
  width: 100%;
  margin-bottom: .5rem;
  display: flex;
  justify-content: flex-end;
}

.delete__modal-close img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.delete__modal-title {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.delete__modal-subtitle {
  margin-bottom: 1.5rem;
}

.delete__modal-subtitle span {
  font-weight: 700;
  color: var(--green);
}

.delete__form {
  display: flex;
  flex-direction: column;
}

.delete__form label span {
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
}

.delete__form input {
  border: 1px solid var(--blue);
  width: 100%;
  padding: .25rem;
  box-sizing: border-box;
}

.delete__form button {
  margin: 2rem 0 1rem 0;
  padding: .5rem;
  background: var(--red);
  border-radius: .5rem;
  color: var(--white);
  align-self: center;
  cursor: pointer;
}

.delete__form button.inactive {
  cursor: not-allowed;
  opacity: .5;
}

.confirmation__icon {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.confirmation__text {
  margin-bottom: 2rem;
}

.delete__modal-container.center button {
  padding: .5rem;
  border-radius: .5rem;
  color: var(--white);
  cursor: pointer;
}

.delete__modal-container.center button.green {
  background: var(--dark-green);
}

.delete__modal-container.center button.red {
  background: var(--red);
}

@media screen and (min-width: 950px) {

  .main__account {
    flex-direction: row;
    padding: 0;
  }

  .account__section {
    width: 70%;
    height: 100%;
    padding: 4rem 8rem;
  }

  .delete__modal-title {
    font-size: 2rem;
  }

  .delete__form button {
    padding: 1rem;
  }

  .delete__modal-container {
    max-width: 40rem;
  }
}
