.primary-button__content {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    transition: 0.1s;
}

.primary-button {
    display : inline-flex;
    color: var(--white);
    background: var(--dark-green);
    border : 2px solid var(--dark-green);
    border-radius: 0.5rem;
    padding : 0.6rem;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

@media (min-width : 480px) {
    .primary-button__content {
        font-size: 16px;
    }

    .primary-button {
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    }

    .primary-button:hover {
        background: var(--white);
        color: var(--dark-green);
    }
}
