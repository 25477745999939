.title_container {
  gap: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.title_container__subtitle {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  color: var(--dark-green);
  margin-bottom: 2rem;
}

.searchBar_container {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 950px) {
  .title_container__subtitle {
    font-size: 1.25rem;
  }
}

.build_img {
  padding: 1.5rem;
  margin-top: 1.5rem;
}

.build_text {
  color: var(--dark-green);
  font-family: Public Sans, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  /* text-transform: uppercase; */
}
