.register-confirm__container span {
    color: var(--dark-green);
    font-family: Public Sans, sans-serif;
    font-style: normal;
    line-height: normal;
}

.register-confirm__container {
    padding: 1rem;
    height: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 4rem;
}

.reset-confirm__check {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.reset-confirm__sent {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.reset-confirm__chek-mail {
    font-size: 0.875rem;
    font-weight: 400;
}

@media screen and (min-width: 950px) {
    .background__container {
        display: block;
        height: 100%;
        width: 30%;
        overflow: hidden;
        background: url('../../public/img/bg1.png');
        background-size: cover;
    }

    main {
        flex-direction: row;
        padding: 0;
    }

    .register-confirm__container {
        width: 70%;
        padding-left: 6rem;
        gap: 0.5rem;
        margin-bottom: 12rem;
    }

    .reset-confirm__title {
        width: 70%;
    }

    .reset-confirm__sent {
        font-size: 1.5rem;
    }

    .reset-confirm__chek-mail {
        font-size: 1.125rem;
    }

}
