.widget-tempgraph {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: none;
    gap: 8px;
}

.widget-tempgraph__left {
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    gap: 8px;
}

.widget-tempgraph__left--colmun {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    gap: 4px;
}

.widget-tempgraph__left--colmun__candle {
    background-color: var(--dark-green);
    height: 80%;
    width: 12px;
    border-radius: 12px;
}

.widget-tempgraph__left--colmun__data {
    text-align: center;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.widget-tempgraph__left--colmun__icon {
    width: 18px;
}

.widget-tempgraph__separator {
    display: none;
    height: 90%;
    width: 1px;
    background-color: var(--light-blue);
    border-radius: 1rem;
}

.widget-tempgraph__right {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-left: 4px;
    gap: 1rem;
}

.widget-tempgraph__right--title {
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.widget-tempgraph__right--data {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.widget-tempgraph__right--unit {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (min-width: 800px) {
    .widget-tempgraph__right {
        display: flex;
    }

    .widget-tempgraph__separator {
        display: block;
    }
}
