.modale {
    padding: .5rem;
    border: 2px solid;
    border-radius: .5rem;
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity .3s ease-in;
}

.modale.hide {
    opacity: 0;
}

.success {
    background: rgba(31, 122, 140, 0.25);
    border-color: var(--green);
    color: var(--green);
}

.success .modale__btn--svg {
    fill: var(--green);
}

.fail {
    background: rgba(214, 31, 31, 0.25);
    color: #D61F1F;
    border-color: #D61F1F;
}

.fail .modale__btn--svg {
    fill: #D61F1F;
}

.modale__msg {
    margin-right: .75rem;
}

.modale__btn--svg {
    width: .75rem;
    height: .75rem;
}
