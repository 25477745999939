.widget-main {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--dark-green);
  text-align: center;
  font-family: Public Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 0.5rem;
  justify-content: space-between;
  position: relative;
}

.widget-title {
  font-size: 0.8rem;
}

@media (min-width: 480px) {
  .widget-title {
    font-size: 1rem;
  }
}

.obstruct__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .8);
  z-index: 10;
  border-radius: 1rem;
}

.widget-footer {
  margin-bottom: 0.5rem;
  height: 1px;
  width: 40%;
  background: var(--dark-green);
}

.widget-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 60px;
  height: 60px;
  /* animation: blur 2s ease-out infinite; */
}

.widget-loader .line1 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
}

.widget-loader .line2 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .3s;
}

.widget-loader .line3 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .2s;

}

.widget-loader .line4 {
  opacity: 0;
  animation: blur 2s ease-out infinite;
  animation-delay: .1s;
}


@keyframes blur {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }
}
