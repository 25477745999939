.red-button__content {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  cursor: pointer;
  transition: 0.1s;
}

.red-button {
  display: inline-flex;
  color: var(--red);
  background: var(--white);
  border: 2px solid var(--red);
  border-radius: 0.5rem;
  padding: 0.6rem;
  transition: 0.3s;
  cursor: pointer;
}

@media (min-width: 480px) {
  .red-button__content {
    font-size: 16px;
  }

  .red-button {
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
  }

  .red-button:hover {
    background: var(--red);
    color: var(--white);
  }
}
