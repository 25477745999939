.widget-digtal-hour__data {
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (min-width: 480px) {
    .widget-digtal-hour__data {
        font-size: 3rem;
    }
}
