.confirm__infos {
  font-family: "Public Sans", sans-serif;
  color: var(--dark-green);
  margin: 3rem 0 2rem 0;
}

.reset__main {
  min-height: 80vh;
}

.confirm__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation__result {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0 2rem 0;
}

.validation__h1 {
  font-family: "Public Sans", sans-serif;
  color: var(--dark-green);
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.5rem;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .validation__h1 {
    font-size: 4rem;
    line-height: 5.5rem;
  }
}

@media screen and (min-width: 950px) {
  .confirm__container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .validation__h1 {
    font-size: 6rem;
    line-height: 7rem;
  }
}
