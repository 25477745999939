.widget-visibility-data {
    font-size: 3rem;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
}

.widget-visibility-unit {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
